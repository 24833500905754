import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import ItemStyle from './item.module.scss'

const Item = ({ name }) => {
  return (
    <Link className={ItemStyle.relatedTagItem} to={`/blog/tags/${name}`}>
      <span># {name}</span>
    </Link>
  )
}

Item.propTypes = {
  name: PropTypes.string.isRequired,
}

export default Item

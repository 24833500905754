import React from 'react'
import PropTypes from 'prop-types'
import PostDateStyle from './index.module.scss'
import CalendarIcon from '../../img/icon-calendar.svg'

const PostDate = ({ text }) => (
  <div className={PostDateStyle.postDate}>
    <img src={CalendarIcon} alt="Calendar icon" />
    <p>{text}</p>
  </div>
)

PostDate.propTypes = {
  text: PropTypes.string.isRequired,
}

export default PostDate

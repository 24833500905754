import React from 'react'
import PropTypes from 'prop-types'
import RelatedTagsStyle from './relatedTags.module.scss'
import TagIcon from '../../img/icon-tag.svg'
import Item from './Item'

const RelatedTags = ({ tags }) => {
  return (
    <div className={RelatedTagsStyle.relatedTags}>
      <img src={TagIcon} alt="Tag icon" />
      <ul>
        {tags.map(item => (
          <li key={item.name}>
            <Item name={item.name} />
          </li>
        ))}
      </ul>
    </div>
  )
}

RelatedTags.propTypes = {
  tags: PropTypes.array.isRequired,
}

export default RelatedTags
